<template>
  <article id="wrap">

    <header>
      <img src="@/assets/img/sutekinasekai_card.png" class="w100">
    </header>


    <section class="contents">

      <div class="contentInner">
        <h2>月額登録完了</h2>
        <div>すてきなせかいへようこそ！</div>

        <div class="bt_form mtb_20"><router-link :to="{name:'home'}">トップへ</router-link></div>

      </div><!--.contentInner-->

    </section><!--.contents-->

  </article>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'SubscriptionEnd',
  created () {
    this.$store.commit('setLastReadTalkRoom', this.$route.params.talk_room);
  }
}
</script>
